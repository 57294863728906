import { FC } from 'react';

import { Script } from 'gatsby';
import { useTranslation } from 'react-i18next';

import useSiteMetadata from '@/hooks/useSiteMetadata';

import { SEOProps } from './SEO.types';

const SEO: FC<SEOProps> = ({ title, titleKey }) => {
    const { t } = useTranslation();
    const siteMetadata = useSiteMetadata();
    const pageTitle = titleKey ? t(titleKey) : title || siteMetadata.title;

    return (
        <>
            <title>{pageTitle}</title>
            <meta name="description" content={siteMetadata.description} />
            <meta name="keywords" content={siteMetadata.keywords} />

            {/* Facebook */}
            <meta property="og:url" content={siteMetadata.url} />
            <meta property="og:type" content={siteMetadata.type} />
            <meta property="og:title" content={siteMetadata.title} />
            <meta property="og:description" content={`${siteMetadata.title} - ${siteMetadata.description}`} />
            <meta property="og:image" content={`${siteMetadata.url}/thumbnail.png`} />

            {/* Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={siteMetadata.url} />
            <meta name="twitter:title" content={siteMetadata.title} />
            <meta name="twitter:description" content={siteMetadata.description} />
            <meta name="twitter:image" content={`${siteMetadata.url}/thumbnail.png`} />

            <Script>
                {`(function(d, t) {
                var v = d.createElement(t), s = d.getElementsByTagName(t)[0];
                v.onload = function() {
                window.voiceflow.chat.load({
                    verify: { projectID: '6781d3e98259b83ec49b63b9' },
                    url: 'https://general-runtime.voiceflow.com',
                    versionID: 'production'
                });
                }
                v.src = "https://cdn.voiceflow.com/widget/bundle.mjs"; v.type = "text/javascript"; s.parentNode.insertBefore(v, s);
            })(document, 'script');`}
            </Script>
        </>
    );
};

export default SEO;
