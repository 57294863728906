import { FC, useMemo } from 'react';

import { Table, Thead, Tbody, Tr, Th, Td, TableCaption, Box, Icon, chakra, HStack, Text } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { MdCheckCircle, MdError, MdWarning } from 'react-icons/md';

import { FourPropertyStatus } from '@/enums';
import useFourProperties, { FourProperty } from '@/hooks/four/useFourProperties';
import { hoveredFourPropetyAtom, selectedFourPropetyAtom } from '@/state/four/fourProperties';
import { formatPrice } from '@/utils/formatPrice';
import { formatNumber } from '@/utils/four/formatNumber';

import { FourPropertiesTableProps } from './FourPropertiesTable.types';

interface IStatusIcon {
    property: FourProperty;
}

const StatusIcon: FC<IStatusIcon> = ({ property }) => {
    if (property.statusText === FourPropertyStatus.AVAILABLE) {
        return <Icon as={MdCheckCircle} color="green.300" mr={3} />;
    } else if (property.statusText === FourPropertyStatus.RESERVED) {
        return <Icon as={MdWarning} color="orange.300" mr={3} />;
    } else if (property.statusText === FourPropertyStatus.RESERVED2) {
        return <Icon as={MdWarning} color="pink.300" mr={3} />;
    }
    return <Icon as={MdError} color="red.300" mr={3} />;
};

const FourPropertiesTable: FC<FourPropertiesTableProps> = ({
    showDisclaimer = true,
    status = true,
    compressed = false,
    onModalOpen,
    ...boxProps
}) => {
    const { t } = useTranslation();
    const [properties] = useFourProperties();
    const [, setSelectedProperty] = useAtom(selectedFourPropetyAtom);
    const [hoveredProperty, setHoveredProperty] = useAtom(hoveredFourPropetyAtom);

    const filteredProperties = useMemo(
        () =>
            properties.filter(property =>
                status
                    ? property.statusText === FourPropertyStatus.AVAILABLE
                    : property.statusText !== FourPropertyStatus.AVAILABLE,
            ),
        [properties, status],
    ).sort((a, b) => a.number - b.number);

    const onMouseEnter = (property: FourProperty) => () => {
        setHoveredProperty(property);
    };

    const onMouseLeave = () => {
        setHoveredProperty(null);
    };

    const handlePropertyClick = (property: FourProperty) => () => {
        if (onModalOpen) {
            setSelectedProperty(property);
            onModalOpen();
        }
    };

    return filteredProperties.length > 0 ? (
        <Box w="full" overflowX="auto" {...boxProps}>
            <Table size="sm" colorScheme="gray">
                {showDisclaimer && (
                    <TableCaption>
                        <chakra.sup>*</chakra.sup> {t('land.scheme.disclaimer')}
                    </TableCaption>
                )}
                <Thead>
                    <Tr>
                        <Th>{t('common.number')}</Th>
                        <Th>
                            {t('filters.size')} <Text fontSize="xs">{t('common.sqm')}</Text>
                        </Th>
                        {!compressed && (
                            <Th>
                                {t('four.scheme.streetArea')} <Text fontSize="xs">{t('common.m')}</Text>
                            </Th>
                        )}
                        <Th isNumeric>
                            {t('filters.price')} {t('common.withVat')}
                        </Th>
                        {!compressed && (
                            <>
                                <Th textAlign="center" colSpan={1}>
                                    {t('land.scheme.headers.status')}
                                </Th>
                            </>
                        )}
                    </Tr>
                </Thead>
                <Tbody>
                    {filteredProperties.map(property => (
                        <Tr
                            key={property.number}
                            onMouseEnter={onMouseEnter(property)}
                            onMouseLeave={onMouseLeave}
                            onClick={handlePropertyClick(property)}
                            cursor="pointer"
                            transition="0.15s"
                            bg={
                                hoveredProperty && hoveredProperty.number === property.number
                                    ? 'gray.100'
                                    : 'white'
                            }>
                            <Td>
                                <HStack spacing={1}>
                                    <StatusIcon property={property} />
                                    <Text>{formatNumber(property.number)}</Text>
                                </HStack>
                            </Td>
                            <Td>
                                <Text>{property.size}</Text>
                            </Td>
                            {!compressed && (
                                <Td>
                                    <Text>{property.streetArea}</Text>
                                </Td>
                            )}

                            <Td isNumeric>
                                {!property.price || property.price === 0 ? '' : formatPrice(property.price)}
                            </Td>
                            {!compressed && (
                                <Td textAlign="center">{t(`four.scheme.statuses.${property.statusText}`)}</Td>
                            )}
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    ) : null;
};

export default FourPropertiesTable;
