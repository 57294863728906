import { FC } from 'react';

import {
    Button,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Modal,
    Flex,
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { Trans } from '@/components/v2';
import { activeModalIdAtom } from '@/state/modals/modals';

import { ModalWrapperProps } from './ModalWrapper.types';

const ModalWrapper: FC<ModalWrapperProps> = ({ children }) => {
    const { t } = useTranslation();
    const [activeModalId, setActiveModalId] = useAtom(activeModalIdAtom);

    const handleClose = () => {
        setActiveModalId(null);
    };

    return (
        <>
            <Modal isOpen={!!activeModalId} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t(`modals.${activeModalId}.title`)}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody whiteSpace="pre-wrap">
                        <Trans i18nKey={`modals.${activeModalId}.body`} />
                    </ModalBody>
                    <ModalBody whiteSpace="pre-wrap">
                        <Trans i18nKey={`modals.${activeModalId}.footer`} />
                    </ModalBody>
                    <ModalFooter width="full" justifyContent="center">
                        <Flex width="full" justifyContent="flex-end" alignItems="center">
                            <Button mr={3} onClick={handleClose}>
                                {t('common.close')}
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {children}
        </>
    );
};

export default ModalWrapper;
