exports.components = {
  "component---src-pages-2024-details-tsx": () => import("./../../../src/pages/2024/details.tsx" /* webpackChunkName: "component---src-pages-2024-details-tsx" */),
  "component---src-pages-2024-index-tsx": () => import("./../../../src/pages/2024/index.tsx" /* webpackChunkName: "component---src-pages-2024-index-tsx" */),
  "component---src-pages-2024-scheme-tsx": () => import("./../../../src/pages/2024/scheme.tsx" /* webpackChunkName: "component---src-pages-2024-scheme-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-house-index-tsx": () => import("./../../../src/pages/house/index.tsx" /* webpackChunkName: "component---src-pages-house-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-land-details-tsx": () => import("./../../../src/pages/land/details.tsx" /* webpackChunkName: "component---src-pages-land-details-tsx" */),
  "component---src-pages-land-index-tsx": () => import("./../../../src/pages/land/index.tsx" /* webpackChunkName: "component---src-pages-land-index-tsx" */),
  "component---src-pages-land-scheme-tsx": () => import("./../../../src/pages/land/scheme.tsx" /* webpackChunkName: "component---src-pages-land-scheme-tsx" */),
  "component---src-templates-query-template-query-template-tsx": () => import("./../../../src/templates/QueryTemplate/QueryTemplate.tsx" /* webpackChunkName: "component---src-templates-query-template-query-template-tsx" */),
  "component---src-templates-water-results-template-water-results-template-tsx": () => import("./../../../src/templates/WaterResultsTemplate/WaterResultsTemplate.tsx" /* webpackChunkName: "component---src-templates-water-results-template-water-results-template-tsx" */)
}

