import { FC, MouseEvent, useEffect, useState } from 'react';

import { CloseIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton, Progress, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { motion } from 'framer-motion';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { TOAST_AUTO_CLOSE_DURATION } from '@/constants/landing/toasts';
import { activeModalIdAtom } from '@/state/modals/modals';
import { toastsAtom } from '@/state/toasts/toasts';

import { ToastProps } from './Toast.types';

const MotionBox = motion(Box);

const toastVariants = {
    hidden: { x: '100%', opacity: 0 },
    visible: { x: 0, opacity: 1 },
    exit: { x: '100%', opacity: 0 },
};

const Toast: FC<ToastProps> = ({ toast, index, shouldHide, onToastHide }) => {
    const [progress, setProgress] = useState(100);
    const setToasts = useSetAtom(toastsAtom);
    const setAcitveModalId = useSetAtom(activeModalIdAtom);
    const { t } = useTranslation();

    const onToastOpen = (toastId: string) => () => {
        setAcitveModalId(toastId);
    };

    const onToastClose = (toastId: string) => (e?: MouseEvent<HTMLButtonElement>) => {
        if (e) e.stopPropagation();
        setToasts(prev => prev.map(toast => (toast.id === toastId ? { ...toast, isActive: false } : toast)));
    };

    useEffect(() => {
        if (shouldHide) {
            const interval = setInterval(() => {
                setProgress(prev => {
                    if (prev <= 0) {
                        clearInterval(interval);
                        onToastHide(toast.id);
                    }
                    return prev - 1;
                });
            }, TOAST_AUTO_CLOSE_DURATION / 100);
            return () => clearInterval(interval);
        }
    }, [index, toast.id, onToastHide, shouldHide]);

    return (
        <MotionBox
            width="full"
            position="relative"
            overflow="hidden"
            p="2"
            pb="3"
            bg="white"
            borderRadius="md"
            shadow="md"
            cursor="pointer"
            onClick={onToastOpen(toast.id)}
            variants={toastVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{
                type: 'spring',
                stiffness: 300,
                damping: 30,
                delay: index * 0.2,
            }}>
            <Flex justifyContent="space-between">
                <Flex direction={['column', 'row']} alignItems={['flex-start', 'center']}>
                    <Box p="1" borderRadius="md" background="gray.100">
                        <Text color="gray.700" fontSize="xs" fontWeight="light">
                            {format(new Date(toast.date), 'dd/MM/yyyy')}
                        </Text>
                    </Box>
                    <Text ml={['0', '2']} mt={['1', '0']}>
                        {t(`modals.${toast.id}.headline`)}
                    </Text>
                </Flex>
                <IconButton
                    ml="4"
                    size="xs"
                    icon={<CloseIcon boxSize="2" />}
                    aria-label="Close Toast"
                    onClick={onToastClose(toast.id)}
                />
            </Flex>
            <Progress
                position="absolute"
                left="0"
                bottom="0"
                width="full"
                size="xs"
                value={progress}
                colorScheme="green"
                transition="width 0.1s linear"
            />
        </MotionBox>
    );
};

export default Toast;
